/*
Отработка и вывод только на клиенте, на сервере вернет null
*/
import { useState, useEffect} from 'react';
import { observer } from "mobx-react-lite" 
import userState from '../../stores/userState';

const ClientOnly = (props) => {
	const [onClient, setOnClient] = useState(false)

    useEffect(() => {
        setOnClient(true);
    }, []);

    if (!onClient) {
        return null;
    }

    return props.children;
}


const LoggedInOnly = observer((props) => {
    const [loggedIn, setLoggedIn] = useState(false)
    useEffect(() => {
        if (props.uids && props?.uids?.length > 0 ) {
            setLoggedIn(userState.loggedIn && props.uids.filter(u => u == userState.id).length > 0);
        } else {
            setLoggedIn(userState.loggedIn);    
        }
    }, [userState.id, userState.loggedIn]);

    if (!loggedIn) {
        return null;
    }
    return props.children;
})


export default ClientOnly;
export {LoggedInOnly};