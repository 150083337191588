/*
Попап для картинок и видео.
TODO: добавить листалку для картинок
*/

import React, { useEffect, useCallback } from 'react';
import popupsState from '../../stores/popupsState';
import { observer } from "mobx-react-lite" ;
import YouTube from 'react-youtube';
import Gallery from '../../components/Gallery/Gallery';
import apirone from '../../utils/api';
import Slider from "@ant-design/react-slick";
import {toJS} from 'mobx';




const Popups = observer((props) => {
  
  
  const GalleryArrow = (props) => {
    const handleClick = (e) => {
        props.onClick(e);
        console.log('GalleryArrow', e);
    }
    return (
      <div onClick={handleClick} className={"gallery_arrow " + (props.prev == undefined ? 'arrow_prev' : 'arrow_next') }>
        <svg width="16" height="16"><use xlinkHref="#i_larr" /></svg>
      </div>
    )
  }
  
  const settings = {
    dots: props.noIcons,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: props.noIcons ? 3 : 1,
    slidesToScroll: 1,
    //lazyLoad: 'ondemand',
    nextArrow: <GalleryArrow />,
    prevArrow: <GalleryArrow prev />,
    
  };


    const ytOpts = {playerVars: {autoplay: 1}};
    const closePopup = useCallback((k) => {
        popupsState.closePopup(k)
    });
    const getPopupMode = (popup) => {
      if (popup.youtube) return 'video';
      if (popup.image) return 'image';
      if (popup.gallery) return 'gallery';
      return 'data';
    }

    const content = Object.keys(popupsState.popups).map((k) => {
        const mode = getPopupMode(popupsState.popups[k]);// popupsState.popups[k].youtube !== undefined ? 'video' : (popupsState.popups[k].image !== undefined ? 'image' : 'data')
        const popupClass = "popup popup_" + mode;
        const actions = popupsState.popups[k].actions ? popupsState.popups[k].actions : [];
        console.log('popupsState.popups[k].index',toJS(popupsState.popups[k]));
        return (
            <div className={popupClass} key={k} id={'popup_' + k} /*onClick={(e) => {closePopup(k)}}*/>
                <div className="popup_header">
                    <div className="popup_title">
                        {popupsState.popups[k].title}
                    </div>
                    <button className="popup_close" onClick={(e) => {closePopup(k)}}>✕</button>
                </div>
                <div className="popup_content" /*onClick={(e) => {closePopup(k)}}*/>
                    {
                      mode == 'video' &&
                      <YouTube  videoId={popupsState.popups[k].youtube} opts={ytOpts}/>
                    }
                    {

                      mode == 'image' &&
                      <>
                            <div className="progress"></div>
                            <img src={popupsState.popups[k].image} alt={popupsState.popups[k].alt} /> 
                        </>
                        
                    }
                    {

                      mode == 'gallery' &&
                      <div data-clicklock="true">
                      <Slider  {...settings} initialSlide={popupsState.popups[k].index} >
                        {popupsState.popups[k].gallery.map((el, i) =>  <img key={i} src={el}/>) }
                      </Slider>
                      </div>
                    }
                    {
                      mode == "data" &&
                      popupsState.popups[k].markup 
                    } 
                </div>
            {
            actions.length > 0 &&
                <div className="popup_actions">
                    {
                        actions.map((a) =>  
                            <button 
                                className="popup_action" 
                                onClick={(e) => {
                                    closePopup(k); 
                                    if (a.execute)
                                        a.execute();
                                }}
                            >{a.title}</button>
                        )
                    }
                </div>
            }
                
            </div>
        );
    });

    if (content.length > 0) {
        return(
            <>
                {content}
                <div className="popup_shadow" ></div>
            </>
        )
    } else {
        return null;
    }
})

export default Popups;
