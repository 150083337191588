/*
mobx стор для поиска листингов 
*/

import { makeObservable, observable, action, computed } from "mobx"
import userState from '../stores/userState';
import listingFormState from '../stores/listingFormState';
import apirone from '../utils/api';
//import {toJS} from 'mobx';


class searchState {
    stateLatest = 'latest';
    stateFeatured = 'featured';
    stateSaved = 'saved';
    stateDefault = this.stateLatest;
    id = false;
    data = [];//description
    values = {};
    search = {};
    searchKey = 0;
    searchFound = [];
    searchFeatured = [];
    searchLatest = [];
    isSearching = false;
    searchState = {show: this.stateDefault};
    observables = {};
    filtersEnabled = false;
    filters = {
        price: [false,false],
        ttsn:[false,false], 
        //seats:[false,false], 
        year: [false,false]
    };


    constructor() {
        this.searchTree()
        this.data = observable.map(this.data);
        makeObservable(this, {
            doSearch: action,
            doSearchLatest: action,
            doSearchFeatured: action,
            //setValue: action,
            isSearching: observable,
            filtersEnabled: observable,
            filters: observable,
            textQuery: computed,
            //formDataset: computed,
            observables: observable,
            //setIsSearching: action,

            search: observable,
            searchFound: observable,
            searchFeatured: observable,
            searchLatest: observable,
            
            searchBoxRefine: action,

            searchKey: observable
        })
    }

    get textQuery() {
        return this.search.query;
    }

    filtersToggle() {
        this.filtersEnabled = !this.filtersEnabled;
    }

    clear(reset) {
        if (reset || !reset && this.id) 
        this.values = [];
        this.errors = [];
        this.id = false;
    }


    doSearchLatest(page = 1) {
        this.isSearching = true;
        apirone.queryPublic('/api/listing_search', {latest: true, page: page}).then((data) => {
            this.isSearching = false;
            if (data && data.found !== undefined) {
                //console.log('latest', data.found)
                if (data.page > 1) {
                    let res = [...this.searchLatest, ...data.found];
                    this.searchLatest = res; 
                } else {
                    this.searchLatest = data.found; 
                }
                //this.searchLatest = data.found;
            } else {
                this.searchLatest = []
            }
        })
        return true;
    }


    doSearchFeatured(page = 1) {
        this.isSearching = true;
        apirone.queryPublic('/api/listing_search', {featured: true, page: page}).then((data) => {
            this.isSearching = false;
            if (data && data.found !== undefined) {
                 if (data.page > 1) {
                    let res = [...this.searchFeatured, ...data.found];
                    this.searchFeatured = res; 
                } else {
                    this.searchFeatured = data.found; 
                }
                //this.searchFeatured = data.found;
            } else {
                this.searchFeatured = []
            }
        })
        return true;
    }





    doSearch(q = false, page = 1) {
        this.isSearching = true;
        apirone.queryPublic('/api/listing_search', {search: q == false ? this.search : q, page: page}).then((data) => {
            this.isSearching = false;
            if (data && data.found !== undefined) {
                if (data.page > 1) {
                    let res = [...this.searchFound, ...data.found];
                    this.searchFound = res; 
                } else {
                    this.searchFound = data.found; 
                }
            } else {
                this.searchFound = []
            }
        })
        return true;
    }

    doSearchSaved(q) {
        if (q.search) {
            if (q.search.query) {
                this.setSearchValue('query', q.search.query);
            } else {
                this.setSearchValue('query', '');
            }
            this.searchKey = q.textQuery;
            if (q.search.category) {
                let key = 'category';
                let field = this.data.get(key);
                let value = q.search[key];
                //this.setSearchValue(key, q.search[key]);
                this.clearDependantFields(key);
                if (field && field.data && field.data.refine) {
                    apirone.queryPublic('/api/listing/refine', {...this.search, [key]: value, trigger: key}).then((data) => {
                        if (data) {
                            this.data.merge(data);
                            this.setSearchValue(key, value);
                            if (q.search.brand) {
                                key = 'brand';
                                field = this.data.get(key);
                                let value = q.search[key];
                                this.clearDependantFields(key);
                                if (field && field.data && field.data.refine) {
                                    apirone.queryPublic('/api/listing/refine', {...this.search, [key]: value, trigger: key}).then((data) => {
                                        if (data) {
                                            this.data.merge(data);
                                            this.setSearchValue(key, value);
                                            if (q.search.model) {
                                                key = 'model';
                                                this.setSearchValue(key, q.search[key]);
                                            }
                                            this.doSearch(this.search);
                                        }
                                    })
                                }
                            } else {
                                this.doSearch(this.search);
                            }
                        }
                    })
                } else {
                    this.doSearch(this.search);
                }
            }

            /*Object.keys(q.search).forEach(key => {
                this.searchBoxRefine(key, q.search[key]);
            })*/
            
        }
    }


    async saveSearch() {

        const listFind = (id, list) => {
            var found = 'Undefined';
            list.forEach(item => {
                if (item.id == id) found = item.title;
            })
            return found;
        }
        const text = this.search.query;
        const titles = [];

        if (this.search.category && this.data.get('category') && this.data.get('category').list) {
            titles.push('(' + listFind(this.search.category,this.data.get('category').list) + ')');
        }
        if (this.search.brand && this.data.get('brand') && this.data.get('brand').list) {
            titles.push(this.data.get('brand').list[this.search.brand]);
        }
        if (this.search.model && this.data.get('model') && this.data.get('model').list) {
            titles.push(this.data.get('model').list[this.search.model]);
        }
        var title = '';
        if (text && text !== '') {
            title = text + ', ';
        }
        title += titles.join(' ');
        return await apirone.query2('/api/search_save', {search: this.search, title: title});
    }

    async unsaveSearch(id) {
        return apirone.query2('/api/search_unsave', {id: id});
    }


    searchBoxList(field) {
        if (this.data.get(field) !== undefined) {
            return this.data.get(field).list
        } else {
            return [];
        }
    }

    setSearchValue(key, value) {
        if (key == 'category') {
            this.search['brand'] = null;
            this.search['model'] = null;
        }
        if (key == 'brand') {
            this.search['model'] = null;
        }
        if (value == null) {
            delete this.search[key];
        } else {
            this.search[key] = value;
        }   
    }

    searchBoxRefine(key, value) {
        let field = this.data.get(key);
        this.setSearchValue(key, value);
        this.clearDependantFields(key);
        if (field && field.data && field.data.refine) {
            apirone.queryPublic('/api/listing/refine', {...this.search, [key]: value, trigger: key}).then((data) => {
                if (data) {
                    this.data.merge(data);
                }
            })
        }
    }


    getSearchValue(key, ifUndefined = undefined) {
        if (this.search[key] == undefined) {
            return ifUndefined
        }
        return this.search[key];
    }


    searchTree() {
        apirone.queryPublic('/api/listing_search_tree',{}).then((data) => {
            if (data) {
                if (data.fields !== undefined) {  
                    this.data.merge(data.fields);   
                }
            }
        }) 
    }


    /*setValue(key, value) {
        if (value == null) {
            delete this.values[key];
            return;
        }
        let field = this.data.get(key);
        this.values[key] = value;
        this.clearDependantFields(key);
        if (field && field.data) {
            if (field.data.refine) {
                apirone.queryPublic('/api/listing/refine', {...this.values, trigger: key}).then((data) => {
                    if (data && undefined !== data._trigger)
                        this.clearDependantFields(data._trigger);
                    this.data.merge(data);
                }) 
            }

            if (field.data.observable) {
                this.observables[key] = value;
                //this.data.get(key).multiplicator = value;
            }
        }
    }*/


    clearDependantFields(key) {
        let deps = new Map();
        let loop = true;
        let ups = 0;
        const add = (x) => {
            
            if (!deps.has(x)) {
                deps.set(x,true);
                return 1;
            }
            return 0;
        }
        while (true) {
            this.data.forEach((f) => {
                if (f.data && f.data.depends ) {
                    if (f.data.depends == key) ups+= add(f.slug);
                    deps.forEach((tmp, k) => {
                        if (f.data.depends == k) ups+= add(f.slug);
                    });
                }
            });
            if (ups == 0) {
                break;
            }
            ups = 0;
        }
        //console.log('CLEAR',deps, key);
        deps.forEach((tmp, k) => {
            if (k !== key) {
                this.search[k] = null;  
                this.data.get(k).list = {};       
            }

            
            //if (f.data.depends == k) ups+= add(f.slug);
        });
        //console.log('deps 4 '+ key,deps);
    }
}

export default new searchState();