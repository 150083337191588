/*
Иконка юзера для шапки
*/

import React from 'react';
import { observer } from "mobx-react-lite" 
import { useNavigate } from "react-router-dom";
import userState from '../../stores/userState';
import popupsState from '../../stores/popupsState';


const UserBage = observer(({defaultText}) => {
    const navigate = useNavigate();
    if (userState.loggedIn) {
        return (
            <a href="/my" onClick={(e) => {e.preventDefault(); popupsState.switchProfile(false); navigate('/my');}} className={"userBage loggedIn_" + userState.loggedIn}>
                {userState.loggedIn ? userState.getUserName : "Unregistered"}
            </a>
        )
    } else {
        if (defaultText) {
            return (<a href="/login" className="userBage">{defaultText}</a>)
        } else {
            return null;
        }
    }
})



const UserLogoutButton = observer(() => {
        const logout = (e) => {
            e.preventDefault();
            userState.logout();
            popupsState.switchProfile(false);
        }
        if (userState.loggedIn) {
            return (
                <a href="/login" className="userLogoutButton" onClick={logout}>
                    <span className="h3">Log out</span>
                </a>
            );
        } else {
            return (
                <span>Not logged in</span>
            );
        }
    }
)

export  {
  UserBage,
  UserLogoutButton
}