import {useState} from 'react';
import TextLine from '../../../components/forms/TextLine/TextLine';


function PasswordFields({onChange, value}) {

    const [state, setState] = useState({password: value, password2: value, valid: false, match: true, errors: [], typed: false});

    const doValidate = (changedState) => {
        const stateErrors = [];
        var valid = true;
        var match = true;
        if (changedState.password && typeof changedState.password == 'string') {
            if (changedState.password.length < 8) {
                stateErrors.push('Password should be at least 8 symbols long.');
            }
            const hasUppercase = /[A-Z]/.test(changedState.password);
            const hasLowercase = /[a-z]/.test(changedState.password);
            const hasNumerics = /\d/.test(changedState.password);
            const hasSpecials = /\W/.test(changedState.password);
            const complexity = hasUppercase * 1 + hasLowercase * 1 + hasNumerics * 1 + hasSpecials * 2;
            if (complexity < 3) {
                stateErrors.push('Password is too weak. Use uppercase, lowercase, digits and special symbols to make it complex.');
                valid = false;
            }
            if (changedState.password !== changedState.password2) {
                stateErrors.push('Type the same password twice.');
                match = false;
            }
        } else {
            valid = false;
            match = true;
        }
        
        return {...changedState, match: match, valid: valid, errors: stateErrors};
        //setState();
    }

    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => {
            const newState = doValidate({...prevState, [id] : value, typed: true});
            if (onChange) {
                if (newState.valid && newState.match) {
                    onChange({target: {id: 'password', value: newState.password}});
                } else {
                    onChange({target: {id: 'password', value: null}})
                }
            };
            return newState;
        });
    }

    return (
        <div className="password_fields">
            <TextLine autocomplete="false" required error={state.typed && !state.valid} label="Password" placeholder="Password" type="password" name="password" value={state.password} onChange={handleChange}/>
            <TextLine autocomplete="false" required error={state.typed && !state.match} label="Password confirm" placeholder="Password confirmation" type="password" name="password2" value={state.password} onChange={handleChange}/>
            {
            state.errors.length > 0 &&
            <div className="password_errors">
                {state.errors.map((e, i) => <div className="password_error" key={'e' + i} >{e}</div>)}
            </div>
            }
        </div>
    )
}


export default PasswordFields;

