
function CheckBox(props) {
    const handleChange = (e) => {
        props.onChange({
            target: {
                value: e.target.checked,
                name: e.target.name
            }
        });
      console.log(e.target.value)
      console.log(props.onChange)
    }
    return(
        <div className="form-element form-checkbox">
            <input id={props.name} name={props.name} defaultChecked={props.checked} type="checkbox" onChange={handleChange}/> 
            <label htmlFor={props.name} >{props.label}</label>
        </div>
    )
}

export default CheckBox;
