/*
Стор с попапами и управление всем вплывающим, включая меню
*/


import { makeObservable, observable, action } from "mobx"

class popupsState {
    profileOpen = false
    addOpen = false
    menuOpen = false
    chatMenuOpen = false
    eventsOpen = false
    chatOpen = false
    popups = {}
    //popupMarkup = null

    constructor() {
        makeObservable(this, {
            profileOpen: observable,
            addOpen: observable,
            menuOpen: observable,
            chatMenuOpen: observable,
            eventsOpen: observable,
            chatOpen: observable,
            popups: observable,
            switchProfile: action,
            switchMenu: action,
            switchEvents: action,
            switchChat: action,
            popup: action,
            popupVideo: action,
            popupImage: action,
            popupGallery: action,
            closePopup: action,
            closeAll: action,
        })
    }

    closeAll(exclude) {
      //console.log('closeAll', exclude)
        if (exclude !== 'profileOpen') this.profileOpen = false;
        if (exclude !== 'addOpen') this.addOpen = false;
        if (exclude !== 'menuOpen') this.menuOpen = false;
        if (exclude !== 'chatMenuOpen') this.chatMenuOpen = false;
        if (exclude !== 'eventsOpen') this.eventsOpen = false;
        if (exclude !== 'popupOpen') this.popupOpen = false;
        if (exclude !== 'chatOpen') this.chatOpen = false;
    }

    switchProfile(state = null) {
        if (state === null) {
            this.profileOpen = !this.profileOpen;
        } else {
            this.profileOpen = state;
        }
    }

    switchAddFloater(state = null) {
        if (state === null) {
            this.addOpen = !this.addOpen;
        } else {
            this.addOpen = state;
        }
    }

    switchChat(state = null) {
        if (state === null) {
            this.chatOpen = !this.chatOpen;
        } else {
            this.chatOpen = state;
        }
    }
    
    switchMenu(state = null) {
        if (state === null) {
            this.menuOpen = !this.menuOpen;
        } else {
            this.menuOpen = state;
        }
    }

    switchChatMenu(state = null) {
        if (state === null) {
            this.chatMenuOpen = !this.chatMenuOpen;
        } else {
            this.chatMenuOpen = state;
        }
    }
    
    switchEvents(state = null) {
        if (state === null) {
            this.eventsOpen = !this.eventsOpen;
        } else {
            this.eventsOpen = state;
        }
    }

    switchPopup(state = null) {
        if (state === null) {
            this.popupOpen = !this.popupOpen;
        } else {
            this.popupOpen = state;
        }
    }

    popup(key, title, markup) {
        const message = markup.message ? markup.message : markup;
        this.popups[key] = {
            markup: message,
            title: title
        }            
    }

    popupDialog(key, title, markup, actions) {
        this.popups[key] = {
            markup: markup,
            actions: actions,
            title: title
        }            
    }

    popupVideo(key, title, youtube) {
        this.popups[key] = {
            youtube: youtube,
            title: title
        }
    }

    popupImage(key, title, alt, url) {
        this.popups[key] = {
            image: url,
            title: title,
            alt: alt
        }
    }

    popupGallery(key, title, images, current, index) {
      this.popups[key] = {
        gallery: images,
        title: title,
        current,
        index
      }
    }

    closePopup(key) {
        //console.log('closePopup', key)
        delete this.popups[key];
    }
}

export default new popupsState();
