/* 
Класс, занимающийся загрузкой данных с помощью axios с бэкенда.
*/
import {useState} from 'react';
import axios from 'axios';
import https from 'https';
import {API_BASE_URL} from '../constants/Constants';
import userState from '../stores/userState';


async function refreshAuth() {
    try {
        const newTokenResponse = await axios.post(API_BASE_URL + '/api/token/refresh', {refresh_token: userState.refreshToken}); 
        if (newTokenResponse.status == 200 && newTokenResponse.data && newTokenResponse.data.token) {
            userState.setToken(newTokenResponse.data.token);
            return newTokenResponse.data.token
        }
    } catch(error ) {
        userState.logout();
        return null;
    }
    return null;
}


class apirone {
    baseURL = API_BASE_URL;


    urlfn(url) {
      return url.replace(/^https?:\/\/\w{2,}\.\w{2,}\/(.*?)\/?$/g, '$1').replace(/^\//,'');
    }

    //возвращает promise для SWR, с авторизацией по токену
    swr(url, data = false) {
        if (!userState.token) {
            return null;
        }
        if (data) {
            const q = new axios.create({
                httpsAgent: new https.Agent({  
                    rejectUnauthorized: false
                })
            });
            q.interceptors.response.use(response => response, async error => {
                if (error.response && error.response.status == 401) {
                    const token = await refreshAuth();
                    const headers = { 'Authorization': 'Bearer ' + token };
                    return axios.post(API_BASE_URL + url, data, { headers: headers}).then(res => res)
                }
                return null;
            });
            const headers = { 'Authorization': 'Bearer ' + userState.token };
            
            return q.post(API_BASE_URL + url, data, { headers: headers}).then(res => res.data)
        } else {
            return axios.get(API_BASE_URL + url).then(res => res.data);
        }
    }

    //для запросов без авторизации по токену
    async queryPublic(url, data, formData = false) {
        try {    
            const result = (data == [] || data == false || data == null || data == {}) 
                ?
                await axios.get(API_BASE_URL + url)
                :
                await axios.post(API_BASE_URL + url, data, formData ? { headers: { "Content-Type": "multipart/form-data" } } : {});
            if (result.status === 200) {
                return result.data;
            }
        } catch(error) {
            return null;
        }
        return null;
    }


    async getBlob(url) {
        try {
            const result = await axios({
                url: API_BASE_URL + url,
                method: 'GET',
                responseType: 'blob',
            });
            if (result.status === 200) {
                return result.data;
            }
            
            /*const result = await axios.get(API_BASE_URL + url);
            if (result.status === 200) {
                return result.data;
            }*/
        } catch(error) {
            return null;
        }
        return null;
    }


    async getMedia(url, done){
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.open("GET", url);
        //xhr.responseType = "blob";
        xhr.onload = () => {
            if (xhr.status == 200) {
                const blob = new Blob([xhr.response], {type: "image/webp"});
                const file = new File([blob], url);
                done(file);
            } else {
                done(false);
            }
        }
        xhr.send();
    }

    //Простой post, лучше не использовать
    async post(url, data, auth = false) {
        const headers = auth ? { 'Authorization': 'Bearer ' + auth } : {};
        return await axios.post(API_BASE_URL + url, data, { headers: headers })
    }

    //для запросов с авторизацией по токену и повторный запрос, если токен просрочен. 
    //TODO: ограничить число повторов в случае ошибки на сервере или иной возможности получить токен.
    async query2(url, data) {
        const headers = userState.token ? { 'Authorization': 'Bearer ' + userState.token } : {};
        try {
            const preResponse = await axios.post(API_BASE_URL + url, data, { headers: headers });
            if (preResponse.status == 200 && preResponse.data) {
                if (preResponse.data.userAuth && preResponse.data.userAuth == 'unauthorized') {
                    userState.logout();
                }
                //console.log('preResponse.data',preResponse.data)
                return preResponse.data;
            } else {
                return null;
            }
        } catch(error) {
            if (error.response && error.response.status == 401) {
                //try token refresh
                const newToken = await refreshAuth();
                try {
                    const newResponse = await axios.post(API_BASE_URL + url, data, {headers: {'Authorization': 'Bearer ' + newToken }});
                    if (newResponse.data) {
                        return newResponse.data;
                    } else {
                        return null
                    }
                } catch(error) {
                    return null;
                }
            }
        }
        return null;
    }

    //для запросов с авторизацией по токену (старый вариант, проверить код и убрать)
    query(url, data, success = null, error = null, auth = false) {
        const headers = auth ? { 'Authorization': 'Bearer ' + auth } : {};

        const q = new axios.create({
          httpsAgent: new https.Agent({  
            rejectUnauthorized: false
          })
        });

        q.post(API_BASE_URL+url, data, { headers: headers })
            .then(response => {
                if (response.status === 200) {
                    if (response.data && response.data.userAuth) {
                        if (response.data.userAuth == 'unauthorized') {
                            userState.logout();
                        }
                    }
                    success(response);
                } else {
                    error(response);
                }

            })
        .catch(response => {
                //console.log('APIRONE error', response)
                error(response)
        });
    }
}

export default new apirone;