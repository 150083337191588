/*
Стор с уведомлениями
*/

import { makeObservable, observable, action } from "mobx"



class notificationState {
    list = {}

    constructor() {
        makeObservable(this, {
            list: observable,
            add: action,
            dismiss: action,
        })
    }

    add(key, markup, actions, type = 'notification') {
        const message = markup.message ? markup.message : markup; 
        this.list[key] = {markup: message, actions: actions, type: type};
    }

    dismiss(key) {
        console.log('dismiss', key)
       delete this.list[key];
    }
}

export default new notificationState();