/*
Ссылка меню
*/

import React from 'react';
import { useNavigate } from 'react-router-dom';
import popupsState from '../../stores/popupsState';


function MenuLink({url, title, active, onClick}) {
    const navigate = useNavigate();
    const goto = (page, title, e) => {
        e.preventDefault();
        navigate("/" + page);
    }
    return (
        <a 
            href={"/" + url} 
            className={active ? 'active' : undefined} 
            onClick={(e) => {
                if (typeof onClick == 'function') onClick();
                goto(url, title, e);
                popupsState.switchProfile(false);
            }} 
        >
            {title}
        </a>
    )
}

export default MenuLink;