/*
Список уведомлений на странице
*/

import React, {useEffect, useState, useCallback} from 'react';
import notificationState from '../../stores/notificationState';
import Notification from './Notification';
import { observer } from "mobx-react-lite" 


const Notifications = observer((props) => {
	const list = Object.keys(notificationState.list);
	if (list.length == 0) {
		return null;
	} else {
		return <div className="notifications container">
			{list.reverse().map( key =>  <Notification id={key} data={notificationState.list[key]} /> )}
		</div>
	}
})



export default Notifications;