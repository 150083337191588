/*
mobx стор с данными юзера, авторизаией и т.п. 
*/

import { makeObservable, observable, action, computed } from "mobx"
import apirone from '../utils/api'
import popupsState from './popupsState';
import { useNavigate } from 'react-router-dom';


const goTo = (url) => {
    window.location = '/my';
    return null;
}

class userState {
    nickname = ""
    firstname = ""
    lastname = ""
    publicUUID = false
    privateUUID = false
    avatar = false
    likes = false
    dealer = false
    id = 0
    loggedIn = false
    loggedInTime = 0
    token = false
    refreshToken = false
    expire = 0
    loginProgress = false
    redirectToLogin = false
    errors = []
    loadingLikes = false
    pinger = false
    lCompare = []
    sCompare = []


    getName() {
        return (this.firstname !== '' || this.lastname !== '') 
            ? [this.firstname,this.lastname].join(' ') 
            : this.nickname;
    }

    getPublicData() {
        return {
            name: this.getName(),
            avatar: this.avatar,
            //uid: this.id,
            uuid: this.publicUUID,
        }
    }

    isOwn(uuid) {
        return this.publicUUID == uuid || this.privateUUID == uuid;
    }

    setToken(token) {
        this.token = token;
        this.saveToStorage();
    }
    

    compareListingToggle(id) {
        var index = -1;
        this.lCompare.map((item, itemIndex) => {
            if (item == id) index = itemIndex;
        })
        //const index = this.lCompare.indexOf(id * 1);
        if (index > -1) {  
            this.lCompare.splice(index, 1);
        } else {
            this.lCompare.push(id * 1);
        }
        apirone.query2('/api/user/compare', {listing: this.lCompare, request: this.sCompare});
    }

    get compareActive() {
        return (this.lCompare.length > 0 || this.sCompare.length > 0);
    }

    compareUrl(content) {
        //const this.lCompare = userState.lCompare.map((l) => {return l});
        //const this.sCompare = userState.sCompare.map((s) => {return s});
        if (this.lCompare.length > 0 && this.sCompare.length == 0) {
                return ('/compare-listings?ids='+this.lCompare.join(','));
        }
        if (this.sCompare.length > 0 && this.lCompare.length == 0) {
            return ('/compare/'+this.sCompare.slice().sort().join(','));
        }
        if (this.sCompare.length > 0 && this.lCompare.length > 0) {
            //popupsState.popup('compare', 'Compare', content);
            return true;
        }
        return false;
    }

    compareListingEnabled(id) {
        return this.lCompare.indexOf(id * 1) > -1;
    }

    compareShowroomToggle(id) {
        //console.log(id);
        const index = this.sCompare.indexOf(id);
        if (index > -1) {  
            this.sCompare.splice(index, 1);
        } else {
            this.sCompare.push(id);
        }
        apirone.query2('/api/user/compare', {listing: this.lCompare, request: this.sCompare})
    }

    compareShowroomEnabled(id) {
        return this.sCompare.indexOf(id) > -1;
    }

    get getUserName() {
        if (this.firstname !== '') {
            if (this.lastname !== '') {
                return this.firstname + ' ' + this.lastname;
            } else {
                return this.firstname;
            }
        } else {
            return this.nickname
        }
    }

    updateLikes(result) {
        this.loadingLikes = false;
        if (result && result.result == 'OK' && result.likes) {
            this.likes = {likes: result.likes, sikes: result.sikes};
        }
    }

    updateCompares(result) {
        this.loadingLikes = false;
        if (result && result.result == 'OK' && result.compares) {
            if (result.compares.showroom) {
                this.sCompare = result.compares.showroom;
            }
            if (result.compares.listing) {
                this.lCompare = result.compares.listing;
            }
        }
    }


    async getLikes() {
        if (this.loggedIn) {
            if (!this.loadingLikes) {
                this.loadingLikes = true;
                const result = await apirone.query2('/api/user/likes_compares', {});
                if (result.likes && result.compares) {
                    this.updateLikes(result); 
                    this.updateCompares(result); 
                }
            }
        }
    }



    checkLikes() {
        if (this.loggedIn) {
            if (!this.likes) {
                this.getLikes();
            }
        }
    }

    liked(id, mode) {
        if (this.likes != false) {
            //return this.likes.includes(id);
            if (mode == 1) {
                for (let i = 0; i< this.likes.likes.length; i++) {
                    if (this.likes.likes[i] == id) return true;
                }
            } else {
                for (let i = 0; i< this.likes.sikes.length; i++) {
                    if (this.likes.sikes[i] == id) return true;
                }
            }
        } 
        return false
    }

    async like(id, mode) {
        if (this.loggedIn) {
            const likeResult = await apirone.query2('/api/user/like', mode == 1 ? {listing: id} : {showroom: id});
            this.updateLikes(likeResult);
            return true;    
        }
        return false;
    }

    readStorage() {
        let restore = null;
        if (typeof window !== 'undefined') {
            restore = localStorage.getItem('userState');
        } 
        if (restore && restore !== undefined && restore !== null) {
            restore = JSON.parse(restore);
            if (restore && restore !== null) {
                this.firstname = restore.firstname;
                this.lastname = restore.lastname;
                this.nickname = restore.nickname;
                this.dealer = restore.dealer;
                this.id = restore.id;
                this.publicUUID = restore.publicUUID;
                this.privateUUID = restore.privateUUID;
                this.loggedIn = restore.loggedIn;
                this.loggedInTime = restore.loggedInTime;
                this.token = restore.token;
                this.refreshToken = restore.refreshToken;
                this.expire = restore.expire;
                this.avatar = restore.avatar;
            }
        }
    }

    saveToStorage() {
        if (typeof window !== 'undefined') {
            localStorage.setItem('userState', JSON.stringify({
                firstname: this.firstname,
                lastname: this.lastname,
                nickname: this.nickname,
                dealer: this.dealer,
                avatar: this.avatar,
                privateUUID: this.privateUUID,
                publicUUID: this.publicUUID,
                id: this.id,
                loggedIn: this.loggedIn,
                loggedInTime: this.loggedInTime,
                refreshToken: this.refreshToken,
                token: this.token,
                expire: this.expire,
            }));
        }
    }

    constructor() {
        this.readStorage()
        
        makeObservable(this, {
            loggedIn: observable,
            token: observable,
            id: observable,
            publicUUID: observable,
            privateUUID: observable,
            likes: observable,
            lCompare: observable,
            sCompare: observable,
            avatar: observable,
            like: action,
            updateLikes: action,
            updateCompares: action,
            loginResponse: action,
            errors: observable,
            login: action,
            logout: action,
            pushError: action,
            updateMyself: action,
            compareListingToggle: action,
            compareShowroomToggle: action,
            getUserName: computed,
            firstname: observable,
            lastname: observable,
            compareActive: computed
        })
    }

    pushError(text) {
        this.errors.push(text);
    }

    authenticationError(error){
        this.pushError("Authentication error")
    }

    loginResponse(response) {
        if(response.status === 200) {
            this.token = response.data.token
            this.pinger = setInterval(() => {
                apirone.query2('/api/user/ping', {});
            }, 1000 * 60 * 10);

            this.refreshToken = response.data.refresh_token ? response.data.refresh_token : false;
            let unToken = this.token.split('.');
            if (unToken.length === 3) {
                const tokenData = atob(unToken[1].replace(/\s/g, ''));
                unToken = JSON.parse(tokenData.trim());
                this.loggedIn = true
                this.loggedInTime = (new Date()).getTime();
                this.id = unToken.id
                //console.log('TOKEN UNSAFE', unToken.unsafe)
                const unsafe = JSON.parse(decodeURIComponent(unToken.unsafe.trim()));
                this.nickname = unsafe.username;
                this.dealer = unsafe.dealer;
                this.firstname = unsafe.firstname;
                this.lastname = unsafe.lastname;
                this.avatar = unsafe.avatar;
                this.publicUUID = unsafe.publicUUID;
                this.privateUUID = unsafe.privateUUID;

                if (unsafe.lastLogin == null && unsafe.dealer) {
                    //first time dealer
                    popupsState.popupDialog('firstTimeDealer','Welcome back',
                        <>
                            <h3>Dear dealer!</h3>
                            <p>We are happy to see you again on the updated air.one website, the comprehensive Aircraft
                            Trading Solutions platform.<br />
                            To enjoy all benefits of the platform please confirm your dealer status in your Profile.<br />
                            As a dealer, you will get access to full scope of functions, namely:</p>
                            <ul>
                            <li>Up to 10 free listings during the trial period of one month</li>
                            <li>Instant messaging with your prospects</li>
                            <li>Access to requests from direct prospects and other dealers</li>
                            <li>Access to Off-market and Wanted inventory</li>
                            <li>Dealer’s page</li>
                            </ul>
                            <p>After the trial period, you will be able to subscribe as a member dealer to post unlimited
                            number of listings and preserve all other benefits.</p>
                        </>
                    ,[{title: 'My profile', execute: () => goTo('/my')}]);
                }

                this.saveToStorage();
                
            } else {
                this.pushError("Dramatic error! Hands up and thrilling!");
            }
        }
        else {
            this.pushError("Authentication error");
        }
    }


    login(nickname, password, remember_me, token = false) {
        this.errors = []
        this.loginProgress = true

        apirone.query(
            '/api/login_check',  
            { username: nickname, password: password, remember: remember_me, token: token },
            this.loginResponse.bind(this), 
            this.authenticationError.bind(this)
        );
    }


    logout(redirectToLogin) {
        clearInterval(this.pinger);
        //console.log('LOGOUT!');
        if (redirectToLogin) {
            this.redirectToLogin = true;
            if (window.location !== undefined) {
                window.location.reload;
            }
        }
        this.avatar = false
        this.loggedIn = false
        this.publicUUID = false
        this.privateUUID = false
        //this.logged
        this.likes = false
        this.id = 0
        this.token = false
        this.refreshToken = false
        this.expire = 0
        this.saveToStorage();
    }

    updateMyself(data) {
        if (data.firstname) this.firstname = data.firstname;
        if (data.lastname) this.lastname = data.lastname;
        if (data.nickname) this.nickname = data.nickname;
        if (data.dealer) this.dealer = data.dealer;
        if (data.image) this.avatar = data.image;
        if (data.publicUUID) this.publicUUID = data.publicUUID;
        if (data.privateUUID) this.privateUUID = data.privateUUID;
        //console.log('updateMyself' ,data)
        //TODO image update (icon?)
    }

    async checkAccess(rule, action = false) {
        if (this.loggedIn) {
            const result = await apirone.query2('/api/check_access', rule);
            if (result.result && result.result == 'OK' && result.access !== undefined) {
                if (action) {
                    action(result.access);
                }
                return result.access;
            } else {
                return false;
            }
        }
    }
}

export default new userState();