/*
Стор с данными о предпочтениях пользователя. Загружаем отдельно, хотя можно тянуть при авторизации. 
Надо подумать, не переписать ли...
*/

import { makeObservable, observable, action } from "mobx"
import apirone from '../utils/api';



class configState {
    properties = {}

    constructor() {
        this.properties = observable.map(this.properties);
        makeObservable(this, {
            //properties: observable,
            load: action,
            update: action,
            updateAll: action,
        })
    }

    load(afterLoad = false) {
        apirone.query2('/api/prefs').then(data => {
            if (data && data.data) {
                this.properties = data.data;
                if (afterLoad) afterLoad();
            }        
        })
    }

    update(key, value) {
        this.properties[key] = value;
        apirone.query2('/api/prefs',{[key]: value}).then(data => {
            if (data && data.data) {
                this.properties = data.data;
            }        
        })
    }

    updateAll(data) {
        this.properties = {...this.properties, ...data};
        apirone.query2('/api/prefs', this.properties).then(data => {
            if (data && data.data) {
                this.properties = data.data;
            }        
        })
    }
}

export default new configState();