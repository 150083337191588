/*
Мета-тэги для разных случаев через Helmet
*/

import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';
import { API_BASE_URL } from '../../constants/Constants';


function stripTags(s) {
	if (!s) return null;
	return s.replace(/<[^>]*>/g,' ').replace(/\s{1,}/g,' ');
}

function stripTags2(s) {
	if (!s) return null;

	const res = s.replace(/<[^>]*>/g,' ').split('\n');

	const result = res.map((e,i) => <p key={i}>{e.replace(/\s{1,}/g,' ').trim()}</p>);
	return result;
}

function globalUrl(url) {
	return (url.includes('https://') ? url : API_BASE_URL + url).replace(/\/$/,'');
}


function Meta(props) {
	const seo = (props.seo && props.seo.title !== '' && props.seo.description !== '') ? props.seo : {title: false, description: false};
	const title = stripTags(seo.title ? seo.title : props.title);
	const description = stripTags(seo.description ? seo.description : props.description);
	const location = useLocation();
	
	const imageAlt = stripTags(props.imageAlt ? imageAlt : (props.image ? title : null));
	const image = props.image ? props.image : null;
	const price = props.priceLabel ? props.priceLabel : (props.price ? props.price : null);
	const url = globalUrl(props.url ? props.url : location.pathname);

	return(
		<Helmet>
			<meta charSet="utf-8" />
			<meta property="og:type" content="website" />
			<meta property="og:site_name" content="air.one" />
			<meta property="og:locale" content="en_US" />
			<meta property="og:type" content="website" />
			<meta name="twitter:card" content="summary_large_image" />
			<meta property="article:publisher" content="https://www.facebook.com/AirOneTeam/" />

	
			<title>{title}</title>
			<meta property="og:title" content={title} />
			<meta name="twitter:title" content={title} />
{ imageAlt && 
			<meta name="twitter:image:alt" content={imageAlt} /> 
}
{ image && 
			<meta property="og:image" content={image} /> 
}
{ image && 
			<meta name="twitter:image" content={image} /> 
}

			<meta property="og:description" content={description} />
			<meta name="twitter:description" content={description} />
			<meta name="description" content={description} />
{ price && 
			<meta property="product:price:amount" content={price} /> 
}
{ !props.priceLabel &&	
			<meta property="product:price:currency" content="USD" /> 
}
{url && 
			<meta property="og:url" content={url} />
}
{url && 
			<link rel="canonical" href={url} />
}
{props.modified && 
			<meta property="article:modified_time" content={new Date(props.modified * 1000).toISOString()} />
}

		</Helmet>
	)
}

export default Meta;
export {stripTags, stripTags2};

