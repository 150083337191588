/*
Обертка для форм с капчей. Загружает капчу, закидывает токен
*/

import React, {useRef, useEffect, useState, useCallback} from 'react';
import { makeObservable, observable, action, computed } from "mobx"
import { observer } from "mobx-react-lite" 
import { ReCaptcha } from 'react-recaptcha-v3';
import { loadReCaptcha } from 'react-recaptcha-v3'


//небольшой mobx стор для хранения токена капчи
class captchaTokenHolder {
    token = false;

    constructor() {
        makeObservable(this, {
            token: observable,
            setToken: action,
        })
    }

    setToken(token) {
        this.token = token;
    }
}

const captchaStore = new captchaTokenHolder();

const CaptchaProtected = observer((props) => {
    const recaptcha = useRef(null);

    useEffect(() => {
        loadReCaptcha('6LffWGkeAAAAAAJaUlZGIrnhnVrxw9AfzOMNqZJZ');
        setTimeout(() => {
            if (recaptcha.current) {
                recaptcha.current.execute();
            }
        }, 100)
    },[]);

    const refresh = useCallback(() => {
        recaptcha.current.execute();
    })

    return (
        <div className="captcha_form">
            {
                (captchaStore.token === false)
                ?
                <div className="CaptchaHolder">...</div>
                :
                React.Children.map(props.children, child =>
                    React.cloneElement(child, { token: captchaStore.token, refresh: refresh })
                )
            }
            <div className="captcha_stuff">
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </div>
            <ReCaptcha
                ref = {recaptcha}
                sitekey="6LffWGkeAAAAAAJaUlZGIrnhnVrxw9AfzOMNqZJZ"
                action='submit'
                verifyCallback={captchaStore.setToken.bind(captchaStore)}
            />
        </div>
    )
})

export default CaptchaProtected;
export {captchaStore};