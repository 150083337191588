import {useState, useEffect} from 'react';
import apirone from "../../utils/api";

const Avatar = (props) => {
    const [state, setState] = useState({src: props.src ? props.src : props.defaultSrc});

    const handleImageErrored = () => {
        setState({src: props.defaultSrc});
    }
    if (!state.src || state.src=='') return null;
    
    const urlFinal = (state.src && state.src.indexOf(apirone.baseURL) >= 0) ? state.src : apirone.baseURL + state.src;

    const key = props.src ? props.src : 'undefinedKey'; 

    return(
        <div className={props.className ? props.className : 'avatar'}>
            <img className={state.loaded ? 'loaded' : 'default'}
                src={urlFinal} 
                srcSet={props.srcSet}
                key={key}
                //id={}
                alt={props.alt !== undefined ? props.alt : 'air.one airplane marketplace'} 
                title={props.title !== undefined ? props.title : 'Airplane marketplace'}  
                onError={handleImageErrored.bind(this)}
            />
        </div>
    )
}
export default Avatar;



