/*
mobx стор с данными приложения, передаваемыми с бека при статическом рендере
*/

import { makeObservable, observable, action } from 'mobx';

class appStateStore {

    cmsData = false;
    contactSent = false;

    setCmsData(data) {
        this.cmsData = data;
    }

    setContactSent(sent) {
        console.log('SENT', sent);
        this.contactSent = sent; 
    }

    init(initialState) {
        this.cmsData = initialState && initialState.appstate && initialState.appstate.cmsData ? initialState.appstate.cmsData : false;
    }

    reset() {
        this.cmsData = false;
    }

    constructor(initialState?: { appstate: appState }) {
        //console.log('initialState', initialState)
        makeObservable(this, {
            cmsData: observable,
            contactSent: observable,
            setContactSent: action,
            setCmsData: action,
            init: action,
        })
        this.cmsData = initialState && initialState.appstate && initialState.appstate.cmsData ? initialState.appstate.cmsData : false;
    }

    toJson() {
        return {
            cmsData: this.cmsData
        };
    }
}

const appState = new appStateStore();

export default appState;