/*
Уведомление на странице с кнопкой закрыть
*/

import {useCallback} from 'react';
import notificationState from '../../stores/notificationState';


function Notification(props) {
	const data = props.data;
	const key = props.id ? props.id : 'undefined';
	const type = data.type ? data.type : 'notification';
	const close = useCallback(() => {
		notificationState.dismiss(key);
	})

	return (
		<div className={"notification notification_" + type + " " + key.toLowerCase()}>
			<div className="notification_message">{data.markup}</div>
			<div className="notification_actions">
				<button className="notification_close" onClick={close}>✕</button>
			</div>
		</div>
	)
}

export default Notification;