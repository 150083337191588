/*
Блок меню для вошедшего пользователя
*/

import React , {useState, useEffect, useCallback} from 'react';
import { observer } from "mobx-react-lite" 
import {UserBage, UserLogoutButton} from '../UserBage/UserBage';

import CompareBlock from '../../components/CompareBlock/CompareBlock';
import userState from '../../stores/userState';
import popupsState from '../../stores/popupsState';
import notificationState from '../../stores/notificationState';
import appState from '../../stores/appState';

import MenuLink from '../../components/MenuLink/MenuLink';
import { useNavigate ,Navigate } from 'react-router-dom';


const clientSide = !!(
    (typeof window !== 'undefined' &&
        window.document && window.document.createElement)
    );


const CompareLink = observer(() => {
    const navigate = useNavigate();
    const url = userState.compareUrl();
    const linkUrl = (url === true || url === false) ? '/compare' : url;


    const handleCompare = useCallback((e) => {
        e.preventDefault();
        popupsState.switchProfile(false);
        const result = userState.compareUrl();
        if (result) {
            if (result === true) {
                popupsState.popup('compare', 'Compare', <CompareBlock />)
            } else {
                navigate(result);
            }
        } else {
            navigate('/compare');
            //notificationState.add('nothing_to_compare','Nothing to compare. Add planes from Marketplace or Showrooms.');
        }
    })

    return (
        <a 
            id="link_compare" 
            href={linkUrl}
            className={"compare_mob" + (userState.compareActive ? ' can_compare' : '')} 
            onClick={handleCompare}>
            Compare
        </a>
    )
})


function CommonItems() {
    return(
        <>
            <ul id="menu_mob" >
                
                
                <li><MenuLink url={"new-aircraft-showroom"} title={"Showroom"} /></li>
                <li><MenuLink url={"aircraft-marketplace"} title={"Marketplace"} /></li>
                <li><CompareLink /></li>
                <li><MenuLink url={"dealers"} title={"Dealers"} /></li>
                <li><MenuLink url={"faq"} title={"FAQ"} /></li>
                <li><MenuLink url={"contact"} title={"Contact"} onClick={() => appState.setContactSent(false)} /></li>
                
            </ul>
           
        </>
    )
}

/*
 <ul role="navigation" id="menu_service" >
                <li><MenuLink url={"aircraft-finance"} title={"Aircraft finance"} /></li>
                <li><MenuLink url={"aircraft-insurance"} title={"Aircraft insurance"} /></li>
                <li><MenuLink url={"aircraft-valuations"} title={"Aircraft valuations"} /></li>
                <li><MenuLink url={"airone"} title="About us" /></li>
            </ul>

*/

const ProfileBlock = observer((profileOpen) => {

    const navigate = useNavigate();

    if (userState.loggedIn) {
        return (
            <nav data-clicklock="true" className={"menu_popup profileOpen_" + popupsState.profileOpen} id="menu_profile">
                <CommonItems />
                <ul id="menu_mob" >
                    <li><MenuLink url={"my/create-listing"} title={"Add listing"} /></li>
                    <li><MenuLink url={"my/inventory"} title={"My inventory"} /></li>
                    
                    
                    <li><MenuLink url={"my/favourites"} title={"Favourites"} /></li>
                </ul>
                <ul id="profile_main">

                    <li><UserBage /></li>
                    <li><UserLogoutButton /></li>
                </ul>
            </nav>
        )
    } else {
        return (
            <nav data-clicklock="true" className={"menu_popup profileOpen_" + popupsState.profileOpen} id="menu_profile">
                <CommonItems />
                <ul id="profile_main">
                <li><MenuLink url={"my"} title={"Log in"} /></li>
                </ul>
            </nav>
        )        
    }

})

export default ProfileBlock;

