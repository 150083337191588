/*
Иконка соцсетей
*/

function Social(props) {
	if (props.url !== undefined) {
		let type = props.type ? props.type.toLowerCase() : false;
		let hasUrl = false;
		const icons = {facebook: 'fb', instagram: 'ig', linkedin : 'in', twitter: 'tw', youtube: 'yt', phone: 'call', web: 'link', mail: 'mail'};
		const urls = {
			facebook: 'http://www.facebook.com/{name}/', 
			instagram: 'https://www.instagram.com/{name}/', 
			linkedin : 'https://www.linkedin.com/company/{name}/', 
			twitter: 'https://twitter.com/{name}', 
			youtube: 'https://www.youtube.com/c/{name}'
		}
		if (props.url.match(/.*facebook.com.*/)) {type = "facebook"; hasUrl = true;}
		if (props.url.match(/.*instagram.com.*/)) {type = "instagram";/*ig*/ hasUrl = true;}
		if (props.url.match(/.*linkedin.com.*/)) {type = "linkedin"/*in*/; hasUrl = true;}
		if (props.url.match(/.*twitter.com.*/)) {type = "twitter"; hasUrl = true;}			
		if (props.url.match(/.*youtu.*/)) {type = "youtube"; hasUrl = true;}	
		const socialUrl = hasUrl ? props.url : (urls[type] ? urls[type].replace('{name}', props.url.replace(/^[\@\#]/,'')) : props.url);
		const iconType = icons[type] ? icons[type] : 'und';

		if (type)
			return(
	        <a href={socialUrl} target="_blank" className={"social_icon social_" + type} aria-label={type}>
	        	<svg width="24" height="24"><use xlinkHref={"#i_soc_" + iconType} /></svg>
	        </a>
	    );
	} 
	return null;

}

export default Social;