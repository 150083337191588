import { makeObservable, observable, action } from "mobx"



class aiState {
    opened = false
    forcedClose = false
    storageKey = 'aiState131'
    demo = true
    botname = 'Alron'
    history = [{text: `How can I assist you?`, me: false}]
    context = ""

    constructor() {
        this.readStorage()
        makeObservable(this, {
            opened: observable,
            forcedClose: observable,
            demo: observable,
            history: observable,
            context: observable,
            close: action,
            open: action,
            toggle: action,
            setContext: action,
            demoMode: action,
            setMessagePair: action
        })
        this.timer = setTimeout((() => {
            this.demoMode()
        }).bind(this), 5000)
    }

    saveToStorage() {
        if (typeof window !== 'undefined') {
            localStorage.setItem(this.storageKey, JSON.stringify({
                demo: this.demo,
            }));
        }
    }

    readStorage() {
        let restore = null;
        if (typeof window !== 'undefined') {
            restore = localStorage.getItem(this.storageKey);
        } 
        if (restore && restore !== undefined && restore !== null) {
            restore = JSON.parse(restore);
            if (restore && restore !== null) {
                this.demo = restore.demo;
            }
        }
    }

    close() {
        this.opened = false;

    }

    setContext(context) {
        if (this.context != context) {
            console.log('AI context', context)
            //this.history = [{text: `How can I assist you?`, me: false}]
            this.context = context
        }
    }

    demoMode() {
        if (this.demo) {
            this.history = [{text: `Hi, I am ${this.botname}, air.one's virtual assistant. I am an aircraft expert and happy to assist you with any of your questions about private aviation. Please note that I am still learning so sometimes I can be too creative :)`, me: false}]      
        }
    }

    unDemo() {
        if (this.demo) {
            this.demo = false
            this.saveToStorage() 
        }
    }

    open() {
        this.opened = true
        this.unDemo()
    }

    toggle() {
        this.opened = !this.opened
        this.unDemo()
    }

    setMessagePair(userText, aiText) {
        this.history.push({text: userText, me: true})
        this.history.push({text: aiText, me: false})
    }
}

export default new aiState();
