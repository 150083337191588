/*
Стор с данными формы создания листингов
*/

import { makeObservable, observable, action, computed } from "mobx"
import userState from '../stores/userState';
import apirone from '../utils/api';
import {toJS} from 'mobx';

class listingFormState {
    id = false;
    data = [];//description
    values = {};
    /*search = {};
    searchFound = [];
    searchFeatured = [];
    searchLatest = [];
    isSearching = false;
    searchState = {show: 'featured'};*/
    errors = [];
    formErrors = {};
    step = 1;
    lastMandatoryUpdate = false;
    steps = {};
    observables = {};


    constructor() {
        //this.fieldTree();
        this.data = observable.map(this.data);
        makeObservable(this, {
            errors: observable,
            /*doSearch: action,
            doSearchLatest: action,
            doSearchFeatured: action,
            setSearchValue: action,
            setValue: action,
            isSearching: observable,*/
            steps: observable,
            init: action,
            formDataset: computed,
            observables: observable,
            formErrors: observable,
            setFormError: action,
            clearFormErrors: action,
            //setIsSearching: action,

            /*search: observable,
            searchFound: observable,
            searchFeatured: observable,
            searchLatest: observable,*/
            
            lastMandatoryUpdate: observable,
            step: observable,
            changeStep: action,
            /*searchBoxRefine: action,*/
            pushError: action,
        })
    }


    clear(reset) {
        if (reset || !reset && this.id) 
        this.values = [];
        this.errors = [];
        this.id = false;
    }


    setFormError(key, step, title) {
        this.formErrors[key] = {key, step, title};
    }

    clearFormError(key) {
        delete this.formErrors[key];
    }

    formErrorsCount() {
        return Object.keys(this.formErrors).length;
    }


    clearFormErrors() {
        this.formErrors = {};
    }


    fieldTree() {
        apirone.queryPublic('/api/listing/fieldtree',{}).then((data) => {
            if (data) {
                if (data.fields !== undefined) {  
                    let first = false;
                    this.steps = Object.keys(data.steps).map((key) => {
                        if (!first) first = data.steps[key].id;
                        return data.steps[key];
                    });
                    this.step = first;

                    this.data.merge(data.fields);   
                }
            }
        }) 
    }

    nextStep(direction = 1,move = false) {
        var current = -1;
        this.steps.forEach((s, i) => {
            if (this.step == s.id) {
                current = i;
            }
        });
        
        if (current >= 0) {
            if (current + direction < this.steps.length && current + direction >= 0) {
                if (this.steps[current + direction].id !== undefined) {
                    const newStepKey = this.steps[current + direction].id;
                    if (move) {
                        this.changeStep(newStepKey);
                        //this.step = newStepKey;
                    }
                    return newStepKey;
                }
            }             
        }

        return -1;
    }

    changeStep(newStep) {
        var newStepKey = false;
        this.steps.forEach((s, i) => {
            //console.log(s.id, s.title);
            if (newStep == s.id) {
                newStepKey = newStep;
                //this.step == newStep
            }
        });
        if (newStepKey) {
            this.step = newStepKey;
        } 
    }

    setValue(key, value) {
        if (value == null) {
            delete this.values[key];
            return;
        }
        let field = this.data.get(key);
        this.values[key] = value;
        this.clearDependantFields(key);
        if (field && field.data) {
            if (field.data.refine) {
                apirone.queryPublic('/api/listing/refine', {...this.values, trigger: key}).then((data) => {
                    if (data && undefined !== data._trigger)
                        this.clearDependantFields(data._trigger);
                    this.data.merge(data);
                }) 
            }

            if (field.data.observable) {
                /*let field = this.data.get(key);
                field.data.multiplicator = 1;
                this.data.merge({[key]: field});*/
                this.observables[key] = value;
                //this.data.get(key).multiplicator = value;
            }
        }
    }


    getEnabled(key) {
        const field = this.data.get(key);
        //console.log(key, field);
        if (field && field.data && field.data.depends) {
            const value = this.values[field.data.depends];
            //console.log('DEPS' ,key, value)
            return !(value == undefined || value == '' || value == false || value == null);
        }
        return true;
    }


    getValue(key, ifUndefined = undefined) {
        //console.log('GET ' + key,this.values[key]);

        if (this.values[key] == undefined) {
            const field = this.data.get(key);

            if (field && field.data && field.data.default) {
                return field.data.default
            } 
            return ifUndefined
        }
        return this.values[key];
    }

    setFeatureValue(key, feature, value) {
        //let field = this.data.get(key);
        if (this.values[key] == undefined) this.values[key] = {};
        this.values[key][feature] = value;
        //console.log(this.values[key]);
    }

    getFeatureValues(key) {
        if (this.values[key] == undefined) {
            return {}
        }
        return this.values[key];
    }

    checkFields() {
        this.clearFormErrors();
        let pass = [];
        let errorStep = false;
        this.data.forEach(field => {
            if (field.mandatory) {

                const key = field.slug;
                //console.log(field, this.values[key], this.values[key] ? this.values[key].constructor.name : false, this.values[key] ? Object.keys(this.values[key]).length : false);
                if (this.values[key] && (this.values[key].constructor.name != 'Object' || (this.values[key].constructor.name == 'Object' && Object.keys(this.values[key]).length > 0))) {
                    const value = this.values[key];
                    //console.log(key, value);
                    if (value == null || value == false || value == '' || (value.trim && value.trim() == '')) {
                        this.setFormError(key, field.step_id, field.title);
                        if (!errorStep) errorStep = field.step_id;
                        pass.push(key);
                    }
                } else {
                    this.setFormError(key, field.step_id, field.title);
                    if (!errorStep) errorStep = field.step_id;
                    pass.push(key);
                }
            }
        })
        /*Object.keys(this.values).forEach((key) => {
            //console.log(key);
            const field = this.data.get(key);
            const field_indexed = this.data.get(key.replace(/[0-9]*$/, ''));
            if (((field && field.mandatory) || (field_indexed && field_indexed.mandatory)) && 
                (this.values[key] == null || this.values[key] == undefined || this.values[key].trim() == '')) {
                pass.push(key);
            }
        });*/
        if (errorStep) this.changeStep(errorStep);
        return pass.length == 0 ? true : pass;
    }

    init(id, data, lists) {
        //const media = data.Media;
        //delete data.media;
        //console.log('MEDIA',media)
        //console.log('lists', lists)
        //console.log('processing data',data);

        this.id = id;
        this.data.merge(lists.brands);
        this.data.merge(lists.models);
        //this.data.forEach((field) => {
            //console.log(field.slug);
            /*if (field.data && field.data.multiple_group) {
                //console.log(field.slug, data[field.slug]);
                data[field.slug].forEach((v, i) => {
                    data[field.slug + i] = v;
                });
            }*/
            /*if (data[field.slug] !== undefined && (typeof data[field.slug]).toLowerCase() == 'array') {
                console.log('array!', data[field.slug])
                if (data[field.slug].length == 1) {
                    data[field.slug] = data[field.slug][0];
                } else {
                    data[field.slug].forEach((v, i) => {
                        data[field.slug + i] = v;
                    });
                }
            }*/
            
        //})
        //console.log(data);
        this.values = data;

        //this.values['media'] = media;
    }

    async upload() {
        const check_result = this.checkFields();
        //console.log('check_result',check_result);
        if (check_result === true) {
            var formData = new FormData();
            let field;
            Object.keys(this.values).forEach((key) => {
                const value = this.values[key];
                const field = this.data.get(key);
                //console.log(key, {'field': toJS(field), value: value});
                const isMedia = field && (field.type == 'Media' || field.type == 'File' );
                if (value !== null) {

                    if ((typeof value).toLowerCase() == 'array' ) {
                        
                        value.forEach(el => {
                            formData.append(key+'[]', el);
                        })
                    } else 
                    if ((typeof value).toLowerCase() == 'object' && !isMedia  ) {
                        if (field && field.type == 'Features') {
                            formData.append(key, JSON.stringify(value));
                        } else {
                            Object.keys(value).forEach(k => {
                                formData.append(key+'[]', value[k]);
                            })    
                        }
                    }
                    else {
                        if (isMedia) {
                            //console.log('UPLOAD FILE',value, typeof value);

                            //add files separately
                            const fileOrder = [];
                            var fileOrderIndex = 0;
                            if (typeof value == 'string') {
                                //single old
                                if (value.constructor == File) {
                                    formData.append(
                                        key, 
                                        new Blob([value], {type: value.type}), 
                                        key
                                    );
                                    fileOrder.push(value.name);
                                } else {
                                    fileOrder.push(value);
                                }
                            } else {
                                //media
                                Object.keys(value).forEach(kew => {
                                    if (value[kew].constructor == File) {
                                        formData.append(
                                            key+'[]', 
                                            new Blob([value[kew]], {type: value[kew].type}), 
                                            kew
                                        );
                                        /*fileOrder[fileOrderIndex] = value[kew].name;
                                        fileOrderIndex++;*/
                                        fileOrder.push(value[kew].name);
                                    } else {
                                        /*fileOrder[fileOrderIndex] = value[kew];
                                        fileOrderIndex++;*/
                                        fileOrder.push(value[kew]);
                                    }
                                });
                            }
                            //console.log(key+'_order', fileOrder);
                            if (fileOrder.length) {
                                formData.append(key+'_order', JSON.stringify(fileOrder));
                            }
                        } else {
                            formData.append(key, value);
                        }
                    }
                }
            });

            const answer = await apirone.query2('/api/listing/post', formData);
            if (answer && answer.result == 'OK') {
                this.values = {};
                return answer;
            }
            return false;
        } else {
            //console.log('CHECK ERROR!')
            return check_result;
        }
    }

    uploadSuccess(data) {
        //console.log(data);
    }

    uploadError(data) {
        //console.log(data);
    }

    demo(data) {
        //console.log(data);
    }

    get formDataset() {
        var stepGroupItems = {};
        this.data.forEach((child, index) => {
            const group = child.group ? child.group : 'default';
            const step = child.step_id ? child.step_id : 'und';
            if (stepGroupItems[step] == undefined) stepGroupItems[step] = {};
            if (child.data && (child.data.multiple_group)) {
                    if (child.data.multiple_group == '-') {
                        //check all values in group
                    } else {
                        const cnt = (this.observables[child.data.multiple_group] == undefined ? this.getValue(child.data.multiple_group) : this.observables[child.data.multiple_group])
                        //console.log('CNT', child.data.multiple_group, child.slug)
                        for (var i = 0; i < cnt; i++) {
                            if (stepGroupItems[step][group] == undefined) { 
                                stepGroupItems[step][group] = [];
                            }
                            if (stepGroupItems[step][group][i] == undefined) {
                                stepGroupItems[step][group][i] = {};
                            }
                            stepGroupItems[step][group][i][child.slug] = child;
                            stepGroupItems[step][group][i][child.slug].index = i;
                        }
                    }
            } else {
                if (stepGroupItems[step][group] == undefined) {
                    stepGroupItems[step][group] = [];
                    stepGroupItems[step][group][0] = {};
                }
                stepGroupItems[step][group][0][child.slug] = child;    
            }               
        });
        return stepGroupItems;
    }

    clearDependantFields(key, forSearch = false) {
        let deps = new Map();
        let loop = true;
        let ups = 0;
        const add = (x) => {
            
            if (!deps.has(x)) {
                deps.set(x,true);
                return 1;
            }
            return 0;
        }
        while (true) {
            this.data.forEach((f) => {
                if (f.data && f.data.depends ) {
                    if (f.data.depends == key) ups+= add(f.slug);
                    deps.forEach((tmp, k) => {
                        if (f.data.depends == k) ups+= add(f.slug);
                    });
                }
            });
            if (ups == 0) {
                break;
            }
            ups = 0;
        }
        deps.forEach((tmp, k) => {
            if (k !== key) {
                if (forSearch) {
                    this.search[k] = null;
                } else {
                    this.values[k] = null; 
                }                
            }

            
            //if (f.data.depends == k) ups+= add(f.slug);
        });
        //console.log('deps 4 '+ key,deps);
    }

    getData() {
        return toJS(this.data);
    }


    pushError(text) {
        this.errors.push(text);
    }

}

export default new listingFormState();