/*
Попап выбора, что сравнивать, листинги или шоурумы
*/

import React, {useState}  from 'react';
import { observer } from "mobx-react-lite";
import userState from '../../stores/userState';
import popupsState from '../../stores/popupsState';
import { useNavigate, redirect } from 'react-router-dom';
import A1Button from '../../components/A1Button/A1Button';

const CompareBlock = observer((props) => {

	const navigate = useNavigate();
	const lids = userState.lCompare.map((l) => {return l});
	const cids = userState.sCompare.map((s) => {return s});

	return (
		<div id="compareBlock" className="compare-block">
			<div className="compare-block-litings">
				{lids.length > 0 &&
					<>
						<A1Button onClick={() => {popupsState.closePopup('compare'); navigate('/compare-listings?ids='+lids.join(','));}}>Marketplace ({lids.length})</A1Button>
						<span> </span>
					</>
				}
				{cids.length > 0 &&
					<A1Button onClick={() => {popupsState.closePopup('compare'); navigate('/compare/'+cids.sort().join(','));}}>Showroom ({cids.length})</A1Button>
				}
			</div>
		</div>					
	);
})

export default CompareBlock;
