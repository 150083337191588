/*
Блок логин/регистрация с капчей
*/

import React, {useState, useRef, useEffect, useCallback} from 'react';
import userState from '../../stores/userState';
import { observer } from "mobx-react-lite" ;
import { useNavigate, useLocation } from 'react-router-dom';
import apirone from "../../utils/api";
import RegisterForm from './RegisterForm';
import LoginForm from './LoginForm';
import CaptchaProtected from '../../components/CaptchaProtected/CaptchaProtected';


const IntroText = () => {
    const location = useLocation();
    const getText = () => {
        if (location.search.match(/.*benefits.*/)) return (
            <>
                <h2>Register now for unlimited access to air.one private aircraft trading solutions!</h2>
                <p>Get full access to showroom and marketplace to compare prices, full specs, contact dealers and original manufacturers directly.</p>
                <p>Access air.one chats - secure & safe form of communication with aircraft sellers and brokers running to a high moral standard—we put people first. We have end-to-end encrypted conversations to protect you and your personal data.</p>
                <p>Request Lounge will allow you to connect with the aviation community directly. We offer first ever solution to connect with the owners and buyers, brokers, OEMs, operators, pilots and open new business opportunities for you.</p>
            </>
        );
            return false;
    }
    const text = getText();
    return text ? (
        <div className="intro container">
            {text}
        </div>
    ) : null;
}

const LoginRegisterBlock = observer((props) => {

    const [state, setState] = useState(props.register ? props.register : false);
    return (
        <main className="page">
            <div className="form_block" id="usergate">
            <ul className="tabs">
                <li className={state ? 'tab' : 'tab active'} onClick={() => {setState(false)}}>Log in</li>
                <li className={!state ? 'tab' : 'tab active'}onClick={() => {setState(true)}}>Register</li>
            </ul>
            {state 
                ?
                <CaptchaProtected><RegisterForm switchForm={() => {setState(false)}} /></CaptchaProtected>
                : 
                <LoginForm switchForm={() => {setState(true)}} />
            }
            </div>
            <IntroText />
        </main>
    )
})


export default LoginRegisterBlock;


/*

            <CaptchaProtected><RegisterForm switchForm={() => {setState(false)}} /></CaptchaProtected>
            : 
            <CaptchaProtected><LoginForm switchForm={() => {setState(true)}} /></CaptchaProtected>

*/