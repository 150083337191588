/*
Форма логина
*/

import React, {useState, useRef, useEffect, useCallback} from 'react';
import userState from '../../stores/userState';
import { observer } from "mobx-react-lite" ;
import CheckBox from '../../components/forms/CheckBox/CheckBox';
import TextLine from '../../components/forms/TextLine/TextLine';
import Button from '../../components/forms/Button/Button';
import A1Button from '../../components/A1Button/A1Button';
import { useNavigate } from 'react-router-dom';
import apirone from "../../utils/api";


const LoginForm = observer((props) => {

    const navigate = useNavigate();

    const nameRef = useRef();
    const passRef = useRef();
    const [state, setState] = useState({
        token: props.token,
        remember_me : false,
        errors: [],
    })

    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }

    const checkboxChange = (e) => {
        const {id , checked} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : checked
        }))
    }

    const handleSubmitClick = (e) => {
        e.preventDefault();
        userState.login(nameRef.current.value, passRef.current.value, state.remember_me, state.token)
        setState({...state, errors: []})
    }

    const className = "form-container" + (userState.errors.length > 0 ? ' error' : '');
    

    return(
            <div className="form_container" id="form_login">
                <h3>Log in</h3>
                <form autoComplete="off" method="POST" >
                    <TextLine ref={nameRef} label="Nickname or email" placeholder="Nickname/Email" name="email" />
                    <TextLine ref={passRef} label="Password" placeholder="Password" type="password" name="password"  />
                    <CheckBox checked={state.remember_me} label="Remember me" name="remember_me" onChange={checkboxChange} />
                    
                    <div className="flex_right form_actions">
                        <A1Button className="short" formWrapper onClick={handleSubmitClick} >Log in</A1Button>
                        <Button className="secondary" onClick={() => {navigate('/oblivion')}} >Lost password?</Button>
                    </div>
                        
                </form>
            { userState.errors.length > 0 && 
                <div className="login_errors message_error">
                    {userState.errors[0]}
                </div>
            }
            </div>
    )
})

export default LoginForm;
