import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import appState from './stores/appState';
import { loadableReady } from '@loadable/component';


declare var window: {
    __INITIAL_STATE__: Object,
    location: {
        pathname: string
    }
};

appState.init(window.__INITIAL_STATE__);

loadableReady(() => {
  hydrate(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById('root')
  )}
);

if (module.hot) {
  module.hot.accept();
}
