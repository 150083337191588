/*
Кнопка в стиле А1 с зеленой каймой
В случае href пропа выводится <a>, иначе выводится <button>
*/

function A1Button(props) {
	const btnTag = props.href ? 'a' : 'button';
	const {formWrapper, className, ...restProps} = props;
	const cssClass = className ? className + ' a1Button' : 'a1Button';
	const children = props.children ? props.children : 'Click me';
	//console.log('className', className);
	const inner = (
		<>
		<span className="a1Button_underlay"></span>
			<span className="a1Button_inner">
			{children}
		</span>
		</>
	);
	const buttonContent = (
		props.href
		?
		<a className={cssClass} href={props.href} {...restProps}>
			{inner}
		</a>
		:
		<button type="button" className={cssClass} onClick={props.onClick} {...restProps}>
			{inner}
		</button>
	);


	return (
		props.formWrapper
		?
		<div className="form-element form-button">
	        {buttonContent}
	    </div>
		:
		<>
		{buttonContent}
		</>
	)
}

export default A1Button;


