import { useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    ReactGA.initialize('UA-106415675-1');
    //console.log('UA-106415675-1 initialized');
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      //console.log('pageview', location.pathname + location.search);
      ReactGA.pageview(location.pathname + location.search);
      //console.log('pageView');
    }
  }, [initialized, location]);
};

export default usePageTracking;