/*
Основной класс приложения, маршрутизация и ленивая загрузка на фронте
*/
import React, { useState, useEffect} from 'react';
//import { BrowserRouter as Router } from "react-router-dom";
import { Route, Routes, Navigate, IndexRedirect } from "react-router-dom";
import loadable from '@loadable/component';
//import { loadReCaptcha } from 'react-recaptcha-v3'

//import ReactDOM from 'react-dom';
import popupsState from "./stores/popupsState";
import userState from "./stores/userState";
//import listingFormState from "./stores/listingFormState";
import notificationState from "./stores/notificationState";
import searchState from "./stores/searchState";
import appState from './stores/appState';
import configState from './stores/configState';
//import matchBox from "./stores/matchBox";

//Common components
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Popups from './components/Popups/Popups';
import ClientOnly from './components/ClientOnly/ClientOnly';

//Special
import LoginRegisterBlock from './components/Usergate/LoginRegisterBlock';

import Meta from './components/Meta/Meta';
import Notifications from './components/Notifications/Notifications';

//Routes
const RedirectPageLazy = loadable(() => import('./pages/RedirectPage/RedirectPage'));
const ChatPageLazy = loadable(() => import('./pages/ChatPage/ChatPage'));
const MyLazy = loadable(() => import('./pages/My/My'));
const DealerLazy = loadable(() => import('./pages/Dealer/Dealer'));
const CreateListingLazy = loadable(() => import('./pages/My/CreateListing/CreateListing'));
const EditListingLazy = loadable(() => import('./pages/My/CreateListing/EditListing'));
const InventoryLazy = loadable(() => import('./pages/My/Inventory/Inventory'));
const FavouritesLazy = loadable(() => import('./pages/My/Favourites/Favourites'));
const MarketplaceLazy = loadable(() => import(/* webpackChunkName: "Marketplace" */'./pages/Marketplace/Marketplace'));
const ListingLazy = loadable(() => import(/* webpackChunkName: "Listing" */ './components/Listing/Listing'));
const ShowroomViewLazy = loadable(() => import('./components/Showroom/ShowroomView'));
const ConstructionPageLazy = loadable(() => import('./pages/ConstructionPage/ConstructionPage'));
const Page404Lazy = loadable(() => import('./pages/404/404'));
const ShowroomLazy = loadable(() => import('./pages/Showroom/Showroom'));  
const ManufacturerShowroomLazy = loadable(() => import('./pages/Showroom/ManufacturerShowroom'));  
const CompareListingsLazy = loadable(() => import('./pages/Compare/CompareListings'));
const CompareShowroomsLazy = loadable(() => import('./pages/Compare/CompareShowrooms'));
const LostPasswordLazy = loadable(() => import('./pages/LostPassword/LostPassword'));
const RecoveryLinkLazy = loadable(() => import('./pages/LostPassword/RecoveryLink'));
const ConfirmLinkLazy = loadable(() => import('./pages/My/ConfirmLink/ConfirmLink'));
const ContactUsLazy = loadable(() => import('./pages/ContactUs/ContactUs'));
const PageLazy = loadable(() => import('./pages/Page/Page'));
const ServiceLazy = loadable(() => import('./pages/Service/Service'));
const PublicProfileLazy = loadable(() => import('./pages/PublicProfile/PublicProfile'));
const RequestLoungeLazy = loadable(() => import('./pages/RequestLounge/RequestLounge'));
const RequestCreateLazy = loadable(() => import('./pages/RequestLounge/RequestCreate'));
const RequestEditLazy = loadable(() => import('./pages/RequestLounge/RequestEdit'));
const DealersLazy = loadable(() => import('./pages/Dealers/Dealers'));

import { observer } from "mobx-react-lite" 
import usePageTracking from './utils/usePageTracking';

//Assets
import SVGIcons from './assets/svgIcons';
import './assets/fonts/fonts.css';

import './app.scss';
import './index.scss';



const stores = {
  userState,
  popupsState,
  //listingFormState,
  notificationState,
  configState,
  searchState,
  //matchBox,
};


const AuthCheck = observer((props) => {
  if (userState.loggedIn) {
    return (<ClientOnly>{props.children}</ClientOnly>)
  } else {
    return (<ClientOnly><LoginRegisterBlock /></ClientOnly>)
  }
})


//Для отладки выводим сторы, можно убрать безопасно
if (typeof window !== 'undefined') {
  window._____APP_STATE_____ = stores;
}


function App() {
    const [title, updateTitle] = useState(null);
    const [errorMessage, updateErrorMessage] = useState(null);
    if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
        usePageTracking();
    }

    const parentLocked = (child) => {
        while (child !== undefined && child !== null && child.tagName.toUpperCase() !== 'BODY'&& child.tagName.toUpperCase() !== 'HTML') {        
            //console.log('CHILD', child);
            if (child.hasAttribute('data-clicklock')) { 

                return true;
            }
            child = child.parentNode;
        }
        return false; 
    }

    const handleClick = e => {
      //console.log(e);
        if (!parentLocked(e.target)) {
          //console.log('e.target',e.target)
            popupsState.closeAll(e.target.getAttribute('data-exclude'));
        }
    };

    const setVHprop = e => {
        document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
    }

    const setScrollClass = e => {
        if (window.scrollY == 0 && document.body.scrollTop == 0) {
            document.body.classList.add('not-scrolled');
        } else {
            document.body.classList.remove('not-scrolled');
        }
    }

    useEffect(
    () => {  
        document.addEventListener("mousedown", handleClick, false);  
        window.addEventListener('resize', setVHprop);
        setVHprop();
        window.addEventListener('scroll', setScrollClass);
        document.body.addEventListener('scroll', setScrollClass);
        setScrollClass();
        //loadReCaptcha('6LffWGkeAAAAAAJaUlZGIrnhnVrxw9AfzOMNqZJZ');
        return () => { document.removeEventListener("mousedown", handleClick, false) }
    }, []);

    if (appState.cmsData?.is404) {
        return <Page404Lazy />
    }

    return (
    <>
        <Meta 
            title={'air.one - Aircraft showroom and marketplace'}
            description={'Research. Compare.  Buy the right aircraft for your mission. We\'ve done the research so you don\'t have to. Access wholesale pricing on new aircraft.'}
            //image={'https://air.one/......'}
            url={'https://air.one/'}
        />

        <Header title={title} />
        <div className="content" id="content_wrapper">
        <Notifications />
            <Routes>
                <Route path="/" element={<PageLazy key="airone" pageKey="airone" />} />
                <Route path="/register" element={<LoginRegisterBlock register={true} showError={updateErrorMessage} updateTitle={updateTitle}/>} />
                <Route path="/new-aircraft-showroom" element={<ShowroomLazy showroomKey="showroom"/>} />
                <Route path="/showroom-props" element={<ShowroomLazy showroomKey="showroom-props"/>} />
                <Route path="/showroom-helicopters" element={<ShowroomLazy showroomKey="showroom-helicopters"/>} />
                <Route path="/aircraft-marketplace" element={<MarketplaceLazy />} />
                <Route path="/contact" element={<ContactUsLazy />} />
                <Route path="/dealers" element={<DealersLazy />} />
                <Route path='/service/:action' element={<ServiceLazy />} />
                <Route path='/test' element={<div><img src="/api/userpic/13902" /></div>} />
                <Route path="/request-lounge" element={<RequestLoungeLazy />} />
                    <Route path="/request-lounge/:requestId" element={<RequestLoungeLazy />} />
                    <Route path="/request-lounge/:requestId/edit" element={<AuthCheck><RequestEditLazy /></AuthCheck>} />
                <Route path="/chat" element={<AuthCheck><ChatPageLazy /></AuthCheck>} />
                <Route path="/login" element={<AuthCheck><RedirectPageLazy /></AuthCheck>} />
                <Route path="/my" element={<AuthCheck><MyLazy /></AuthCheck>} />
                    <Route path="/my/listing/:listingId/edit" element={<AuthCheck><EditListingLazy edit={true}/></AuthCheck>} />
                    <Route path="/my/create-listing" element={<AuthCheck><CreateListingLazy /></AuthCheck>} />
                    <Route path="/my/create-request" element={<AuthCheck><RequestCreateLazy /></AuthCheck>} />
                    <Route path="/my/favourites" element={<AuthCheck><FavouritesLazy /></AuthCheck>} />
                    <Route path="/my/inventory" element={<AuthCheck><InventoryLazy /></AuthCheck>} />
                    <Route path="/my/dealer" element={<AuthCheck><DealerLazy /></AuthCheck>} />
                
                <Route path="/oblivion" element={<LostPasswordLazy />} />
                    <Route path="/oblivion/:recoveryKey" element={<RecoveryLinkLazy />} />
                
                <Route path="/confirm/:recoveryKey" element={<ConfirmLinkLazy />} />
                <Route path="/airone" element={<PageLazy key="airone" pageKey="airone" />} />
                <Route path="/c/:pageKey" element={<PageLazy />} />
                <Route path="/listing/:listingId" element={<ListingLazy />} />
                <Route path="/aircraft-showroom/:aircraft" element={<ShowroomViewLazy />} />
                <Route path="/compare-listings" element={<CompareListingsLazy />} />
                <Route path="/compare/*" element={<CompareShowroomsLazy />} />
                <Route path="/contact/:contactId" element={<AuthCheck><PublicProfileLazy /></AuthCheck>} />
                <Route path="/news/*" element={<PageLazy backTitle="Marketplace" backUrl="/aircraft-marketplace" />} />
                <Route path="/privacy" element={<PageLazy key="privacy" backUrl="/" />} />
                <Route path="/terms-and-conditions" element={<PageLazy key="terms-and-conditions" backUrl="/" />} />
                <Route path="/embraer"      element={<ManufacturerShowroomLazy />} />
                <Route path="/acj"          element={<ManufacturerShowroomLazy />} />
                <Route path="/pilatus"      element={<ManufacturerShowroomLazy />} />
                <Route path="/bombardier"   element={<ManufacturerShowroomLazy />} />
                <Route path="/gulfstream"   element={<ManufacturerShowroomLazy />} />
                <Route path="/dassault"     element={<ManufacturerShowroomLazy />} />
                <Route path="/bbj"          element={<ManufacturerShowroomLazy />} />
                <Route path="/cessna"       element={<ManufacturerShowroomLazy />} />
                <Route path="/beechcraft"   element={<ManufacturerShowroomLazy />} />
                <Route path="/piaggio"      element={<ManufacturerShowroomLazy />} />
                <Route path="/piper"        element={<ManufacturerShowroomLazy />} />
                <Route path="/airbus-helicopters"        element={<ManufacturerShowroomLazy />} />
                <Route path="/bell"        element={<ManufacturerShowroomLazy />} />
                <Route path="/curti"        element={<ManufacturerShowroomLazy />} />
                <Route path="/enstrom"        element={<ManufacturerShowroomLazy />} />
                <Route path="/leonardo"        element={<ManufacturerShowroomLazy />} />
                <Route path="/md-helicopters"  element={<ManufacturerShowroomLazy />} />
                <Route path="/robinson"        element={<ManufacturerShowroomLazy />} />
                <Route path="/sikorsky"        element={<ManufacturerShowroomLazy />} />
                <Route path="/cessna-props"        element={<ManufacturerShowroomLazy />} />
                <Route path="/pilatus-turboprop"        element={<ManufacturerShowroomLazy />} />
                <Route path="/daher"        element={<ManufacturerShowroomLazy />} />

                <Route path="*" element={<PageLazy />} />
            </Routes>
            <Popups />
            <Footer />
        </div>
        <SVGIcons />
    </>
  )  
}

export default App;
