import {useState}  from 'react';


const TextLine = React.forwardRef((props, ref) => {
    const {error, required, disabled, label, type, value,  ...rest} = props;

    const className = "form-element form-text"  
    + (required ? ' required' : '') 
    + (disabled ? ' disabled' : '') 
    + (error ? ' error ' : '');

    const tType = type? type : 'text';
    const [state, setState] = useState({ownValue : value ? value : ''})
    const handleChange = (e) => {
        if (props.onChange) props.onChange(e);
        setState({ownValue: e.target.value});
    }
    return(
        <div className={className}>
            <label htmlFor={props.name}>{props.label}</label>
            <input ref={ref} {...rest} id={props.name} type={tType} required={required} onChange={handleChange} value={state.ownValue}/>
        </div>
    )
})

export default TextLine;