 /*
Подвал
*/

import {useState} from 'react';
import Social from '../Social/Social';
import { useNavigate } from 'react-router-dom';

function Footer(props) {
    const navigate = useNavigate();
    const year = new Date().getFullYear();
    return(
        <footer>
            <div className="container">
                <svg id="footer-logo"><use href="#i_airone" /></svg>
            </div>
            <div className="container">
                <div className="columns-2">
                    <div className="text">
                        Air.one is an aircraft trading platform designed specifically to enhance <strong>business information exchange and cooperation</strong> among private aviation industry’s participants on aircraft sales & acquisitions.
                    </div>
                    <div className="socials right">
                        <Social url="https://www.facebook.com/AirOneTeam/"/>
                        <Social url="https://twitter.com/AirOneTeam"/>
                        <Social url="https://www.instagram.com/Air.One.Team/"/>
                        <Social url="https://www.linkedin.com/company/aironeteam/"/>
                    </div>
                </div>
                <div className="columns copyright">
                    <ul className="links col1">
                        <li><a href="/privacy" onClick={(e) => {e.preventDefault();navigate('/privacy')}}>Privacy policy</a></li>
                        <li><a href="/terms-and-conditions" onClick={(e) => {e.preventDefault();navigate('/terms-and-conditions')}}>Legal terms & condition</a></li>
                    </ul>
                    <div className="text col2">
                        ® {year} air.one community marketplace
                    </div> 
                </div>
            </div>
        </footer>
    )
}

export default Footer;