/*
Форма входа
*/


import React, {useState, useRef, useEffect, useCallback} from 'react';
import userState from '../../stores/userState';
import { observer } from "mobx-react-lite" ;
import CheckBox from '../../components/forms/CheckBox/CheckBox';
import TextLine from '../../components/forms/TextLine/TextLine';
import A1Button from '../../components/A1Button/A1Button';
import { useNavigate } from 'react-router-dom';
import apirone from "../../utils/api";
import {captchaStore} from '../../components/CaptchaProtected/CaptchaProtected';
import PasswordFields from './PasswordFields/PasswordFields';



const RegisterForm = observer((props) => {

    const formUrl = '/api/signup';
    const [state, setState] = useState({
        name: "",
        email : "",
        password : "",
        firstname: "",
        lastname: "",
        agreebox: false,
        errors: [],
        tried: false
    })
    const [CRSFToken, setCRSFToken] = useState(false);
    const nivagate = useNavigate();
    const emailIncorrect = !state.email.match(/^\S+@\S+\.\S+$/);
    const passwordError = !(typeof state.password == 'string');
    const nameError = state.name.length < 3;
    const notAgreed = !state.agreebox;

    const registerError = (e) => {
        console.log(e);
    }

    const updateForm = useCallback((data) => {
        if (data){
            if (data._token) {
                setCRSFToken(data._token);
            }
            if (data.code) {
                if (data.code == 'mailExists') {
                    setState(prevState => ({
                        ...prevState,
                        errors: [<span>There is a user with this email. <a href="/oblivion">Forgot your password?</a></span>]
                    }))
                } 
                else if (data.code == 'userExists') {
                    setState(prevState => ({
                        ...prevState,
                        errors: [<span>There is a user with this nickname. <a href="/oblivion">Forgot your password?</a></span>]
                    }))
                }
                else if (data.code == 'success') {
                    setState(prevState => ({
                        ...prevState,
                        errors: [],
                        signUpSuccess: true
                    }))
                }
                else {
                    setState(prevState => ({
                        ...prevState,
                        errors: [data.message]
                    }))
                }
            }
            /*if (data.result == 'OK') {
                setState(prevState => ({
                        ...prevState,
                        errors: [],
                        signUpSuccess: true
                    }))
            } else {
                
    
            }*/
        }
    })

    if (!CRSFToken) {
        apirone.queryPublic(formUrl).then(updateForm);
    }   

    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }

    const checkboxChange = (e) => {
        const {id , checked} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : checked
        }))
    }

    const handleRegisterClick = (e) => {
        setState(prevState => ({
            ...prevState,
            tried: true
        }));
        e.preventDefault();
        if (!passwordError && !emailIncorrect && !nameError && !notAgreed) {
            const formData = new FormData();
            formData.append('registration_form[_token]', CRSFToken);
            formData.append('registration_form[name]', state.name);
            formData.append('registration_form[email]', state.email);
            formData.append('registration_form[password]', state.password);
            formData.append('registration_form[firstname]', state.firstname);
            formData.append('registration_form[lastname]', state.lastname);
            formData.append('registration_form[captcha]', captchaStore.token);

            apirone.queryPublic(formUrl, formData, true).then(updateForm);
            props.refresh();
        }
    }

    const signInLink = () => {
        props.switchForm ? props.switchForm() : nivagate('/my');
    }

    

    return (
        <div className="form_container" id="form_registration">
            <h3>Registration</h3>
            {
                CRSFToken ?
                
                    state.signUpSuccess  ?
                    <div className="registration_success">
                        We have sent an email with a confirmation link to your email address. In order to complete the sign-up process, please click the confirmation link.
                    </div>
                    :
                    <form autoComplete="off" method="POST" > 
                        <TextLine required error={state.tried && nameError} label="Nickname" placeholder="User name" name="name" value={state.name} onChange={handleChange}  />
                        <TextLine required error={state.tried && emailIncorrect} label="Email" placeholder="Email" name="email" value={state.email} onChange={handleChange} />
                        <PasswordFields value={state.password} onChange={handleChange} />
                        <hr />
                        <TextLine label="Firstname" placeholder="Firstname" name="firstname" value={state.firstname} onChange={handleChange} />
                        <TextLine label="Lastname" placeholder="Lastname" name="lastname" value={state.lastname} onChange={handleChange} />
                        
                        <div className={"agree_box" + ((state.tried && notAgreed) ? ' error' : ' ok')}>
                            <label className="agreement_text" htmlFor="agreebox">
                                <input type="checkbox" name="agreebox" id="agreebox" onChange={checkboxChange}/>
                                I accept&nbsp;
                                <a href="/privacy" target="_blank">Privacy policy</a> 
                                &nbsp;and&nbsp; 
                                <a href="/terms-and-conditions" target="_blank">Legal Terms & Conditions</a>
                            </label>
                        </div>
                        <div className="box-2 button-row">
                            <A1Button onClick={handleRegisterClick} >Register</A1Button>
                        </div>
                            
                    </form>
                
                :
                <div className="loading">...</div>
            }
            
        { state.errors.length > 0 && 
            <div className="register_errors message_error">
                {state.errors[0]}
            </div>
        }

        </div>  
    )
})



export default RegisterForm;
